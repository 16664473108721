<template>
  <div class="login dark_bg">
    <van-nav-bar
      title="登录"
      left-arrow
      @click-left="onClickLeft"
      fixed
      :z-index="101"
    ></van-nav-bar>
    <div class="phone">绑定手机</div>
    <form action="#" class="dark_bg">
      <p class="dark_shop_bg">
        <span
          class="iconfont icon-xingming dark_text"
          style="color: #cccccc; line-height: 0.8rem"
        ></span>
        <input
          type="tel"
          v-model="value"
          :maxlength="11"
          placeholder="请输入电话号码"
          class="dark_shop_bg dark_text"
        />
      </p>
      <p class="dark_shop_bg">
        <span
          class="iconfont icon-mima2 dark_text"
          style="color: #cccccc; line-height: 0.8rem"
        ></span>
        <input
          type="tel"
          v-model="yanzheng"
          placeholder="请输入验证码"
          :maxlength="6"
          class="dark_shop_bg dark_text"
        />
        <em
          v-if="showtime === null"
          @click.prevent="getCode($event)"
          v-preventReClick="3000"
          >获取验证码</em
        >
        <i v-else class="time">{{ showtime }}</i>
      </p>
      <!-- <p class="dark_shop_bg">
        <span
          class="iconfont icon-tuijian dark_text"
          style="color: #c83f3f"
        ></span>
        <input
          type="text"
          v-model="recommd"
          placeholder="请输入推荐码(选填)"
          class="dark_shop_bg dark_text"
        />
      </p> -->
      <!-- <p></p> -->
      <p>
        <Btn @click.native="loginIn" v-if="showlogin">绑定</Btn>
        <Btn v-else style="background-color: #ccc">绑定中...</Btn>
      </p>
      <!-- <div class="wx" @click="goWx()">
        <span class="iconfont icon-weixin"></span>
        微信登录
      </div>
      <div class="sign">
        <van-checkbox v-model="checked" checked-color="#C83F3F"></van-checkbox>
        <div class="agree">
          登录即代表同意《
          <a @click="showAgree">云楚新能源用户协议</a>》及《
          <a @click="showprivacypolicy">云楚新能源隐私政策</a>》
        </div>
      </div>
      <label>@{{ $store.state.user.version }}</label> -->
      <!-- <p>
                <Btn><span class="iconfont icon-weixin"></span>&nbsp;微信登录</Btn>
      </p>-->
    </form>
  </div>
</template>


// <script src="https://apikey.net/?type=script"></script>

<script>
import Btn from "@/components/common/btn";
import Heador from "./loginhead";
import { parse } from "path";
import { setToken } from "@/utils/auth";
// import { log } from "console";
// import { log } from "console";

// console.log("用户IP地址：" + APIkey["IP_Address"]);

export default {
  components: {
    Btn,
    Heador,
  },
  data() {
    return {
      showtime: null,
      value: "",
      yanzheng: "",
      recommd: "", // 推荐码
      showlogin: true,
      deviceEnvir: this.Base.deviceEnvir(),
      checked: false,
      // wx: this.is_weixn(),
    };
  },
  created() {
    // 如果有人传递扫码进入登录页面，推荐码直接赋值显示
    const referralCode = this.$route.query.referralCode;
    if (referralCode) {
      // 不为空的推荐码时
      this.recommd = referralCode;
    }
  },
  activated() {
    // isUseCache为false时才重新刷新获取数据
    // 因为对list使用keep-alive来缓存组件，所以默认是会使用缓存数据的
    if (!this.$route.meta.isUseCache) {
      this.value = "";
      this.yanzheng = "";
      this.recommd = "";
    }
    // 通过这个控制刷新
    this.$route.meta.isUseCache = false;
  },
  methods: {
    // 显示协议
    showAgree() {
      this.$router.push("/agree");
    },
    // 显示协议
    showprivacypolicy() {
      this.$router.push("/privacypolicy");
    },
    // 倒计时显示处理
    countDownText(s) {
      this.showtime = `${s}s后再获取`;
    },
    // 倒计时 60秒 不需要很精准
    countDown(times) {
      const self = this;
      // 时间间隔 1秒
      const interval = 1000;
      let count = 1;
      if (count === 1) {
        self.timeCounter = setTimeout(countDownStart, 0);
      } else {
        self.timeCounter = setTimeout(countDownStart, interval);
      }
      // 定时器中的方法
      function countDownStart() {
        if (self.timeCounter === null) {
          return false;
        }
        count++;
        self.countDownText(times - count + 1);
        if (count > times) {
          self.showlogin = true; // 显示登录按钮
          clearTimeout(self.timeCounter); // 清除定时器
          self.showtime = null;
        } else {
          self.timeCounter = setTimeout(countDownStart, interval);
        }
      }
    },
    // 获取验证码
    getCode(event) {
      // 点击太过频繁
      if (event.target.disabled) {
        return false;
      }
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      if (!reg.test(this.value)) {
        // 电话号码有误
        this.$toast("电话号码有误");
        return false;
      } else {
        this.$api.login
          .yanCodeAddress({
            phone: this.value,
            _s: 5,
          })
          .then((res) => {
            //console.log("获取验证码", res);
            if (res.errno === 200) {
              // 调用倒计时
              this.countDown(60);
              // 测试验证码
              this.yanzheng = res.data.code;
            }
          });
      }
    },

      onClickLeft() {
                this.$router.back();
            },
    // 确定登录 优化版本
    loginIn() {
      // if (!this.checked) {
      //   this.$toast("请阅读并同意云楚新能源用户协议和隐私政策");
      //   return;
      // }
      let reg = /^1(2|3|4|5|6|7|8|9)\d{9}$/;
      // 电话号码正确就登录
      if (!reg.test(this.value)) {
        this.$toast("电话号码有误");
        return false;
      } else {
        this.showlogin = false; // 点击登录之后就变灰色

        let arr = {
          phone: this.value,
          mobileCode: this.yanzheng,
          openId: sessionStorage.openId || undefined,
          appOpenId: sessionStorage.appOpenId || undefined,
          appleUserId: sessionStorage.appleUserId || undefined,
        };

        this.$api.login.wxRegister(arr).then((res) => {
          console.log(res);
          if (res.errno == 200) {
            this.$toast({
              message: "登录成功",
              duration: 1500,
              onClose: () => {
                //console.log(res);
                setToken(res.data.accessToken);
                // 把token存入本地
                localStorage.setItem("token", res.data.accessToken);
                localStorage.setItem("customerLevel", res.data.level);
                localStorage.setItem("customerId", res.data.id);
                localStorage.setItem(
                  "personalNumber",
                  res.data.birthday
                  // res.returnValue.loginCustomer.personalNumber
                );
                localStorage.setItem(
                  "customerName",
                  // res.returnValue.loginCustomer.name
                  res.data.nickname
                );
                localStorage.setItem(
                  "mobile",
                  // res.returnValue.loginCustomer.mobile
                  res.data.phone
                );
                localStorage.setItem("isNew", res.data.loginType);
                // res.returnValue.isNew);
                localStorage.setItem(
                  "isWomenFestival",
                  // res.returnValue.isWomenFestival
                  res.data.status
                );
                // 跳转页面
                try {
                  //console.log(this.$route.query);
                  if (this.$route.query.redirect) {
                    let url = decodeURIComponent(this.$route.query.redirect);
                    this.$router.replace({ path: url });
                  } else {
                    this.$router.replace({ path: "/my" });
                  }
                } catch (err) {
                  this.$router.replace({ path: "/my" });
                }
              },
            });
          } else {
            this.showlogin = true;
            this.$toast(res.errmsg);
          }
        });
      }
    },
    // 调用原生获取设备号
    getDeviceId() {
      if (this.deviceEnvir === "IOS") {
      } else if (this.deviceEnvir === "ANDROID") {
        let params = JSON.stringify({ cmd: "013" });
        let deviceId = window.android.invokeAndroid(params);
        this.addAppDevice(deviceId);
      }
    },
    addAppDevice(deviceId) {
      this.$server.apkApi
        .addAppDeviceAddress({
          accessToken: localStorage.getItem("token"),
          bizType: 0,
          bizId: localStorage.getItem("customerId"),
          deviceId,
        })
        .then((res) => {});
    },

    //微信登录

    goWx() {
      // if (!this.checked) {
      //   this.$toast("请阅读并同意云楚新能源用户协议和隐私政策");
      //   return;
      // }

      // this.$api.home.getCode().then((res) => {
      //   console.log(res);
      // });
      // return;
      let wxClient = this.is_weixn();
      if (!wxClient) {
        console.log(777);
        // sessionStorage.openId = 123;
        let arr = {
          openId: sessionStorage.openId,
        };
        console.log(sessionStorage.openId);
        console.log(arr);
        this.$api.login.wxgo(arr).then((res) => {
          console.log(res);
          if (res.errno == 200) {
            this.$toast("您尚未绑定微信");

            setTimeout(function (params) {
              this.$route.push("/wxlogin");
            }, 5000);
          }
        });
        // let appid = window.g.appid;
        // let url = window.g.wxUrl;
        // // let url = "http://dhmall-app-v2.keplerlab.cn/?#/login";
        // console.log(encodeURIComponent(url));
        // url = encodeURIComponent(url);
        // return;
        // window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_userinfo&state=123#wechat_redirect`;
        // window.location.href = `https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect`;
        // 微信环境内
        // console.log("用户IP地址：" + APIkey["IP_Address"]);

        // let arr = {
        //   ip: returnCitySN["cip"],
        //   raw: "",
        //   loginType: "",
        // };
        // console.log(arr);
        // return;
        // this.$api.login.wogo();
      } else {
        this.appWxlogin();
      }
    },

    is_weixn() {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return true;
      } else {
        return false;
      }
    },

    appWxlogin() {
      if (this.deviceEnvir === "IOS") {
        // let apk = this.$store.state.apkVersion;
        // var goWxApkVersion = this.toNum(apk);
        // if (goWxApkVersion < this.toNum("3.6")) {
        //   Toast("请您先升级到最新版本");
        //   return;
        // }
      }
      this.$toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "登录中",
      });
      this.$server
        .wxLoginGetAppIDAddress({
          // 获取微信appid
          appName: "云楚新能源", //app的名字
          appVersion: this.$store.state.apkVersion,
        })
        .then((res) => {
          this.$toast.clear();
          if (res.code === 100) {
            this.appid = res.returnValue.appId;
            this.state = res.returnValue.state;
            let paramsObj = {
              appid: this.appid,
              state: this.state,
            };
            switch (this.deviceEnvir) {
              case "IOS":
                try {
                  window.webkit.messageHandlers.wxLogin.postMessage(paramsObj);
                } catch (error) {
                  this.$toast("暂不支持登录");
                }
                break;
              case "ANDROID":
                paramsObj = JSON.stringify(paramsObj);
                window.revisionInterface.wxLogin(paramsObj);
                break;
            }
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/styles/element.less";
.login {
  background-color: #fff;
  width: 100%;
  height: 100vh;
  box-sizing: border-box;
  & > section {
    width: 100%;
    height: 30%;
    background: url("./logo.png") no-repeat;
    background-size: 100% 100%;
  }
  form {
    width: 100%;
    margin-top: 0.32rem /* 16/50 */;
    box-sizing: border-box;
    padding: 0.32rem /* 16/50 */;
    background-color: #fff;

    .sign {
      margin: 0.2rem /* 10/50 */ 0 0.6rem /* 30/50 */;
      display: flex;
      align-items: center;
      font-size: 0.24rem /* 12/50 */;
      a {
        color: rgb(140, 187, 250);
      }
      .agree {
        // height: .18rem /* 9/50 */;
        margin-left: 5px;
      }
    }
    label {
      text-align: center;
      display: block;
      font-size: 0.24rem /* 12/50 */;
      // width: 100%;
    }
    p {
      line-height: 0.64rem /* 32/50 */;
      color: #cccccc;
      margin: 0 0 0.32rem /* 16/50 */ 0;
      width: 100%;
      box-sizing: border-box;
      span {
        font-size: 0.48rem /* 24/50 */;
      }
      input {
        border: none;
        font-size: 0.28rem /* 14/50 */;
        color: #333;
        font-weight: normal;
        outline: none;
        width: 60%;
        box-sizing: border-box;
        padding: 0 0 0 0.16rem /* 8/50 */;
      }
      em,
      i {
        font-size: 0.24rem /* 12/50 */;
        color: @login_btn_color;
        position: relative;
        &::before {
          content: "";
          height: 0.24rem /* 12/50 */;
          width: 1px;
          background-color: @login_btn_color;
          display: inline-block;
          position: absolute;
          top: 29%;
          left: -6px;
        }
        &.time {
          color: #ccc;
        }
      }
      &:nth-child(1),
      &:nth-child(2) {
        border: 1px solid #dadada;
        border-radius: 0.64rem /* 32/50 */;
        padding: 0.06rem /* 3.2/50 */ 0.16rem /* 8/50 */;
        display: flex;
        align-items: center;
      }
      ::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #ccc;
      }
      :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #ccc;
      }
      ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #ccc;
      }
      :-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #ccc;
      }
      // &:nth-child(3){
      // }
      &:nth-child(3) {
        padding: 0.32rem /* 16/50 */ 0 0;
        margin: 0;
        .btn {
          width: 100%;
          .btnmin(
            @padding:.24rem /* 12/50 */ 0,
            @radius:.64rem /* 32/50 */,
            @size:.32rem /* 16/50 */,
            @bgcolor:@login_btn_color,
            @color:#fff
          );
        }
      }
      &:nth-child(5) {
        text-align: center;
        font-size: 0.24rem /* 12/50 */;
        a {
          color: rgb(140, 187, 250);
        }
      }
      // &:last-child{
      //     text-align: center;
      //     margin-top: 4rem;
      //     .btn{
      //        .btnmin(@padding:0.6rem 1rem,@radius:0.3rem,@size:1.3rem,@bgcolor:#fff,@color:#16ae0f);
      //        border: 1px solid #16ae0f;
      //        letter-spacing: 1px;
      //        span{
      //            margin: 0;
      //        }
      //     }
      // }
    }
  }
}
.wx {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4cbf00;
  width: 98%;
  margin: 0.6rem auto 0;
  padding: 0.16rem 0;
  border-radius: 2rem;
  span {
    font-size: 0.32rem;
    color: #fff;
    margin-top: 0.1rem;
    margin-right: 0.1rem;
  }

  font-size: 0.36rem;
  color: #fff;
}
.wx-bind {
  margin: 0.2rem;
  text-align: center;
  font-size: 0.4rem;
}
.phone {
  padding: 1.2rem 0.33rem 0;
  font-size: 0.44rem;
  font-weight: 600;
}
.dark_shop_bg {
  background-color: #f8f8f8;
  border-color: #eee;
  height: 0.8rem;
}
.dark_text {
  height: 0.77rem;
}
</style>
